import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-8292994c")
const _hoisted_1 = {
  key: 0,
  class: "your-premium-status-wrapper"
}
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = { class: "overlay" }
const _hoisted_4 = {
  class: "overlay-inner",
  ref: "overlayInner"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PremiumHeader = _resolveComponent("PremiumHeader")!
  const _component_PremiumText = _resolveComponent("PremiumText")!
  const _component_ButtonsComponent = _resolveComponent("ButtonsComponent")!

  return (_ctx.isPremiumModalVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              class: "close",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideYourPremiumStatusComponent && _ctx.hideYourPremiumStatusComponent(...args)))
            }, " ✕ "),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_PremiumHeader, {
                daysRemaining: _ctx.getDaysRemaining,
                isYearlyPlan: _ctx.isYearlyPlan
              }, null, 8, ["daysRemaining", "isYearlyPlan"]),
              _createVNode(_component_PremiumText, {
                isPremiumExpired: _ctx.isPremiumExpired,
                isYearlyPlan: _ctx.isYearlyPlan
              }, null, 8, ["isPremiumExpired", "isYearlyPlan"]),
              _createVNode(_component_ButtonsComponent, {
                isPremiumExpired: _ctx.isPremiumExpired,
                userData: _ctx.userData
              }, null, 8, ["isPremiumExpired", "userData"])
            ], 512)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}