
import { Vue, Options } from "vue-class-component";
import PremiumHeader from "./PremiumHeader/index.vue";
import PremiumText from "./PremiumText/index.vue";
import ButtonsComponent from "./ButtonsComponent/index.vue";
import { calculateDaysRemaining } from "@/utils";
import { UserInterface } from "@/types";
import { usersModule } from "@/store";

const VUE_APP_USER_PREMIUM_STATUS_SHOWN =
  process.env.VUE_APP_USER_PREMIUM_STATUS_SHOWN || "";

@Options({
  name: "YourPremiumStatus",
  components: {
    PremiumHeader,
    PremiumText,
    ButtonsComponent,
  },
  watch: {
    isLoadingUserAccess(val) {
      if (!val) {
        this.checkPremiumStatus();
      }
    },
  },
})
export default class index extends Vue {
  protected isPremiumModalVisible = false;

  protected get isLoadingUserAccess(): boolean {
    return usersModule.isLoading;
  }

  protected get userData(): Partial<UserInterface> {
    return usersModule.userData;
  }

  protected get getDaysRemaining(): {
    days: number;
    hours: number;
    minutes: number;
  } {
    return calculateDaysRemaining(this.userData.accessEndDate!);
  }

  private get isYearlyPlan(): boolean {
    return this.userData.planType === 2;
  }

  protected get isPremiumExpired(): boolean {
    const { days, hours, minutes } = this.getDaysRemaining;
    return days < 0 || (days === 0 && hours <= 0 && minutes <= 0);
  }

  protected hideYourPremiumStatusComponent(): void {
    this.setPremiumStatusShown();
    this.isPremiumModalVisible = false;
  }

  private get isPremiumStatusShown(): boolean {
    return sessionStorage.getItem(VUE_APP_USER_PREMIUM_STATUS_SHOWN) === "true";
  }

  private setPremiumStatusShown(): void {
    sessionStorage.setItem(VUE_APP_USER_PREMIUM_STATUS_SHOWN, "true");
  }

  protected checkPremiumStatus(): void {
    if (this.userData.accessEndDate && !this.isPremiumStatusShown) {
      const { days } = this.getDaysRemaining;
      this.isPremiumModalVisible = this.isYearlyPlan ? days <= 5 : days < 0;
    }
  }
}
